export const apiAuthorizeUrl = 'KEYCLOAK_URL_ENV'
export const apiUrl = 'REST_API_URL_ENV'
export const authEnabled = eval("AUTH_ENABLED_KEYCLOAK_ENV")

// export const apiAuthorizeUrl = ''
// export const apiUrl = 'http://localhost:5000/'
// export const authEnabled = false

// export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
// export const apiUrl = 'https://persistor.dev.gc.axesor.com/'
// export const authEnabled = true

// export const apiAuthorizeUrl = 'https://nep.pre.gc.axesor.com/auth/'
// export const apiUrl = 'wss://persistor.pre.gc.axesor.com/'
// export const authEnabled = true
//
// export const apiAuthorizeUrl = 'https://nep.pro.gc.axesor.com/auth/'
// export const apiUrl = 'wss://persistor.pro.gc.axesor.com/'
// export const authEnabled = true